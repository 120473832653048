/*jshint esversion: 6 */

import DebugUtils from "../common/debug";
import WidgetVideoHistory from "./WidgetVideoHistory";
import * as Utils from "../common/Utils";
import Ajax from "../common/Ajax";
const _d = DebugUtils({debug: true}).log;

/*
   Purpose: Manages incrementing the video views counter.
 */
export default (function (config) {

    if (typeof config.player == "undefined") {
        console.log('Error: player is undefined');
        return;
    }

    if (typeof config.id == "undefined") {
        console.log('Error: video id is undefined');
        return;
    }

    let videoHistory = WidgetVideoHistory({
        videoId: config.id
    });

    videoHistory.show();

    config.player.on('play', function() {

        // Copying values within the scope of the function in case of multiple video players in the page.
        let id = config.id;
        let history = videoHistory;

        let incrementVideoCounter = function (id) {

            let route = Utils.generateRouteFromPattern({
                pattern : {},
                route : `/video/${id}/play`,
                env : ENV,
                locale: LOCALE
            });

            Ajax.getRequest(
                route,
                function(response){
                    _d('Ajax success. Response:');
                    _d(response);
                },
                function(){
                    _d('Error: video not counted')
                },
            );

        };

        if (!history.started()) {
            incrementVideoCounter(id);
        }

        history.started(true).show();
    });

});
