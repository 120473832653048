/*jshint esversion: 6 */

let DebugUtils = function(config) {

    // for debugging using console log messages

    let log = function (text) {
        if (config.debug) {
            console.log(text);
        }
    };

    return {
        log: log,
    };

};

export default DebugUtils;