/*jshint esversion: 6 */

/* for IE 11 */
require("es6-promise").polyfill();
import "isomorphic-fetch";

let Ajax = (function(){

    let getRequest = function (route, successCallback, errorCallback) {
        console.log(`get ${route}`);
        fetch(route, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
            .then(function (response) {
                if (!response.ok) {
                    if(response.status === 401){
                        errorCallback('You must be logged in to perform this action');
                    } else {

                        errorCallback('There has been an error, please try again');
                    }
                } else {
                    response.json()
                        .then(function(response) {
                            successCallback(response);
                        })
                }
            })
            .catch(function (error) {
                errorCallback(error);
            });
    };

    let getRequestHTML = function (route, successCallback, errorCallback) {
        console.log('Sending ajax request')
        console.log(route)
        fetch(route, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
            },
            credentials: 'include'
        })
            .then(function (response) {
                if (!response.ok) {
                    if(response.status === 401){
                        errorCallback('You must be logged in to perform this action');
                    }else{
                        errorCallback('There has been an error, please try again');
                    }
                } else {
                    response.text()
                        .then(function(response) {
                            successCallback(response);
                        })
                }
            })
            .catch(function (error) {
                errorCallback(error);
            });
    };

    let postRequest = function (route, data, successCallback, errorCallback) {
        console.log(`post ${route}`);
        fetch(route, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body:  JSON.stringify(data)
        })
            .then(function (response) {
                if (!response.ok) {
                    if(response.status === 401){
                        errorCallback('You must be logged in to perform this action');
                    }else{
                        errorCallback('There has been an error, please try again');
                    }
                } else{
                    console.log(response);
                    response.json()
                        .then(function(response) {
                            console.log(response);
                            successCallback(response);
                        })
                }
            })
            .catch(function (error) {
                errorCallback(error);
            });
    };

    return {
        getRequest: getRequest,
        postRequest: postRequest,
        getRequestHTML: getRequestHTML,
    };

})();

export default Ajax;