/*jshint esversion: 6 */

import DebugUtils from "../common/debug";
const _d = DebugUtils({debug: true}).log;

const VIDEO_HISTORY_KEY = 'video_history';
const MAX_AGE_MINS = 30;
const USE_LOCAL_VARIABLE = true; // Generally this should be true. Use false for development.

/*
   Purpose: Maintains a history of videos viewed in local storage.
            Uses a local variable (wasStarted) as the main source of truth in case local storage is not available.
 */
let WidgetVideoHistory = function (config) {

    let age = function (tsStarted) {
        let tsNow = Date.now() / 1000 | 0;
        let diff = tsNow - tsStarted;
        let minsElapsed = diff / 60;
        return parseInt(minsElapsed);
    };

    let getVideoHistoryArray = function () {
        let videoHistoryItem = localStorage.getItem(VIDEO_HISTORY_KEY);
        if (videoHistoryItem) {
            return JSON.parse(videoHistoryItem);
        }
        return [];
    };

    let getStatus = function (videoId) {
        let status = {};
        getVideoHistoryArray().map(function(element){
            if (element.id === videoId) {
                status = element;
            }
        });
        return status;
    };

    let checkExpired = function(videoId) {
        let status = getStatus(videoId);
        if (age(status.date) > MAX_AGE_MINS) {
            deleteVideoHistory(videoId);
        }
    }

    let getState = function(videoId) {
        checkExpired(videoId);
        let state = false;
        getVideoHistoryArray().map(function(element){
            if (element.id === videoId) {
                state = element.state;
            }
        });
        return state;
    };

    let setVideoHistory = function(videoId, videoState) {
        _d(`setVideoHistory(${videoId}, ${videoState})`);
        let arrayOfObjects = getVideoHistoryArray();
        let videoStartDate = Date.now() / 1000 | 0;
        let videoFound = false;
        arrayOfObjects.map(function(element){
            if (element.id === videoId) {
                element.state = videoState;
                element.date = videoStartDate;
                videoFound = true;
            }
        });
        if (!videoFound) {
            arrayOfObjects.push({
                id: videoId,
                state: videoState,
                date: videoStartDate,
            });
        }
        localStorage.setItem(VIDEO_HISTORY_KEY, JSON.stringify(arrayOfObjects));
    };

    let deleteVideoHistory = function(videoId) {
        _d(`deleteVideoHistory(${videoId})`);
        let arrayOfObjects = getVideoHistoryArray();
        let itemIndex = -1;
        arrayOfObjects.map(function(element, index){
            if (element.id === videoId) {
                itemIndex = index;
            }
        });
        if (itemIndex >= 0) {
            arrayOfObjects.splice(itemIndex, 1);
            localStorage.setItem(VIDEO_HISTORY_KEY, JSON.stringify(arrayOfObjects));
        }
    };

    let setState = function(state){
        if (config.videoId) {
            setVideoHistory(config.videoId, state);
        }
    };

    // local variable used in case localStorage is not available
    let wasStarted = getState(config.videoId);

    let started = function(value) {
        if (value) {
            wasStarted = value;
            setState(value);
            return this;
        } else {
            if (USE_LOCAL_VARIABLE) {
                return wasStarted || getState(config.videoId);
            }
            return getState(config.videoId);
        }
    };

    let show = function () {
        let status = getStatus(config.videoId);
        if (status.id) {
            console.log(`video:${status.id} started:${status.state} age_mins:${age(status.date)}/${MAX_AGE_MINS}`);
        } else {
            console.log(`video:${config.videoId} - no history`);
        }
        _d('history array:');
        _d(getVideoHistoryArray());
    };

    return {
        started: started,
        show: show
    };

};

export default WidgetVideoHistory;