/*jshint esversion: 6 */

// IE does not support nodeList.forEach()
(function() {
    var foreachPolyfill = {};

    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }
})();
