/*jshint esversion: 6 */

import WidgetSnippets from '../widgets/WidgetSnippets';
import foreachPolyfill from "../common/polyfill-foreach";

function getSnippets(tags, design, maxItems, order, item) {

    let snippetTool = WidgetSnippets({
        locale: LOCALE,
        env: ENV,
        route: "/widget/snippets/get/%tagstring%"
    });

    snippetTool.getSnippetsByTags(tags, design, maxItems, order, item);

}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ensure that the Vimeo object is available in page if required
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// count - how many video snippets are in the page?

let countVideoSnippets = 0;

document.querySelectorAll('snippets').forEach(item => {

    let tags = item.getAttribute('tags');
    if (tags.length > 0) {
        let tagsArray = tags.split(' ');
        if (tagsArray.indexOf('video') > -1) {
            countVideoSnippets++;
        }
    }

});

// ensure the Vimeo object is available by getting the Vimeo script.

if (countVideoSnippets > 0) {
    if (typeof Vimeo === 'undefined') {
        let vimeoScript = document.createElement('script');
        vimeoScript.src = 'https://player.vimeo.com/api/player.js';
        document.head.appendChild(vimeoScript);
        console.log('get Vimeo script');
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



document.querySelectorAll('snippets').forEach(item => {

    let tags = item.getAttribute('tags');
    let design = item.getAttribute('design');
    let maxItems = item.getAttribute('max-items');
    let order = item.getAttribute('order');

    console.log(`snippets with tags: ${tags}  design: ${design}  maxItems: ${maxItems}  order: ${order}`);

    getSnippets(tags, design, maxItems, order, item);

});

