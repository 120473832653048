/*jshint esversion: 6 */

export function replaceValuesInString(mapObj, subject){

    if (Object.keys(mapObj).length === 0) {
        return subject;
    }

    let re = new RegExp(Object.keys(mapObj).join("|"),"gi");

    return subject.replace(re, function(matched){
        return mapObj[matched];
    });
};


/**
 * Get Route from the environment
 * @param   {string} env
 * @returns {string} / | /app_dev.php/ | /app_test.php/
 */
export function getRouteFromEnv(env){
    let route = '';
    if (env === 'dev') {
        route = '/app_dev.php/';
    } else if(env === 'test') {
        route = '/app_dev.php/';
    } else {
        route = '/';
    }

    return route;
};

/**
 * Generate the route from a pattern
 * @param config
 * @returns {string}
 */
export function generateRouteFromPattern(config){

    let prefix = getRouteFromEnv(config.env) + config.locale;

    let pattern = replaceValuesInString(config.pattern, config.route);

    return prefix + pattern;

};

/**
 * Display an alert
 * @param message
 * @param type
 * @param parentEl
 */
export function displayAlert(message, type, parentEl){

    let elParentId = (typeof  parentEl !== 'undefined') ?   parentEl : 'page-content';
    let elParent = document.getElementById(elParentId);

    // Remove any previous flash messages
    removeAlert(elParentId)

    let elChild = document.createElement('div');
    elChild.className = 'flash-message';

    let elMessage = document.createElement('div');
    elMessage.className = 'alert alert-' + type;
    elMessage.innerHTML = message;

    elChild.appendChild(elMessage);

    elParent.prepend(elChild);
};

/**
 * Remove an alert from its parent node
 * @param parentEl
 */
export function removeAlert(parentEl){

    let elParentId = (typeof  parentEl !== 'undefined') ?   parentEl : 'page-content';
    let elParent = document.getElementById(elParentId);
    let alert = elParent.getElementsByClassName('flash-message');
    for (var i = 0; i < alert.length; i++){
        elParent.removeChild(alert[i]);
    }

};

/**
 * Takes an object and sorts the values by alphabetical order
 * Object must be like so { 1: 'Brain', 5: 'Cancer', 74: 'Bone' }
 * The returned array will be sorted like so
 * [{id: 74, value: 'Bone'}, {id: 1, value: 'Brain'}, {id: 5, value: 'Cancer'}]
 *
 * @param obj
 * @returns {Array}
 */
let sortStringObject = function sortStringObject(obj){
    let arr = [];
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            arr.push({
                'key': prop,
                'value': obj[prop]
            });
        }
    }
    arr.sort(function(a, b) {

        return  a.value.toLowerCase().localeCompare(b.value.toLowerCase());
    });

    return arr;
};

export function analyseVariant(variantText){
    return variantText.split('|');
}

export function addCrossBrowserEventListener(element, eventName, listenerFunction) {
    if (element.addEventListener) {
        element.addEventListener(eventName, listenerFunction);
    } else if (element.attachEvent) {
        element.attachEvent('on' + eventName, function(eventName) {
            listenerFunction.call(element, eventName);
        });
    } else if (typeof element['on' + eventName] === 'undefined' || element['on' + eventName] === null) {
        element['on' + eventName] = function(eventName) {
            listenerFunction.call(element, eventName);
        };
    }
};

export function sendCustomEvent(customEventName, data, dataKey = 'postMessageData') {
    if (typeof dataLayer === 'undefined') {
        console.log(`Warning: dataLayer is undefined cannot send ${customEventName}`);
    } else {
        let customEvent = {
            'event': customEventName
        };
        customEvent[dataKey] = data;
        dataLayer.push(customEvent);
    }
}

export default {
    sortStringObject: sortStringObject,
    generateRouteFromPattern: generateRouteFromPattern,
    removeAlert: removeAlert,
    displayAlert: displayAlert
};