/*jshint esversion: 6 */
import Ajax from '../common/Ajax';
import * as Utils from '../common/Utils';
import foreachPolyfill from "../common/polyfill-foreach";
import WidgetVideoViewsManager from "./WidgetVideoViewsManager";

let WidgetSnippets = function(config){


    function createRoute (tags) {
        return Utils.generateRouteFromPattern({
            pattern : {
                '%tagstring%': tags.join('-')
            },
            route : config.route,
            env : config.env,
            locale: config.locale
        });
    }

    // NB This currently makes only a single row of max 4 items
    function responsiveGrid(element, rowClasses) {

        // Clean up the element

        element.classList.add('p-0')
        element.classList.remove('grid');

        // Create a grid using Bootstrap row-cols-* system
        // Create row beneath main snippetContainer

        let row = document.createElement('div');

        // The defaults pertain to a row of 4 cards
        if (rowClasses.length === 0) {
            rowClasses = ['row', 'row-cols-1', 'row-cols-sm-2', 'row-cols-md-4'];
        }

        if (element.classList.contains('grid-2')) {
            rowClasses = ['row', 'row-cols-1', 'row-cols-sm-2'];
        }

        if (element.classList.contains('grid-3')) {
            rowClasses = ['row', 'row-cols-1', 'row-cols-sm-2', 'row-cols-md-3'];
        }

        if (element.classList.contains('grid-4')) {
            rowClasses = ['row', 'row-cols-1', 'row-cols-sm-2', 'row-cols-md-4'];
        }

        if (element.classList.contains('grid-5')) {
            rowClasses = ['row', 'row-cols-1', 'row-cols-sm-2', 'row-cols-md-5'];
        }

        if (element.classList.contains('grid-6')) {
            rowClasses = ['row', 'row-cols-1', 'row-cols-sm-3', 'row-cols-md-6'];
        }

        row.classList.add(...rowClasses);
        element.appendChild(row);

        return row;
    }

    let getSnippetsByTags = function (tags, design, maxItems, order, item) {

        if (!tags) {
            console.log('Warning: no tags specified so no snippets will be used');
            return;
        } else {
            tags = tags.split(' ');
        }

        if (!design) {
            design = [];
        } else {
            design = design.split(' ');
        }

        // The rdesign attribute is the responsive version of the design attribute
        // rdesign should contain sub-row grid-like classes (row-cols-*) which you want to override the grid with
        // Otherwise the default grid classes will be used - see below

        let rdesign = item.getAttribute('rdesign') || '';
        if (!rdesign) {
            rdesign = [];
        } else {
            rdesign = rdesign.split(' ');
        }

        // Classes on the snippets element get copied onto the snippets container
        let classes = item.getAttribute('class') || '';
        if (!classes) {
            classes = [];
        } else {
            classes = classes.split(' ');
        }

        // set defaults

        maxItems = maxItems || 1000000;
        order = order || 'asc';

        let route = createRoute(tags);

        let success = function(response){

            console.log('got {'+tags.join(',')+'}: ' + response.length);

            if (response.length > 0) {

                let snippetContainer = document.createElement('div');
                snippetContainer.classList.add('snippet-container');
                tags.forEach(function(aclass){
                    snippetContainer.classList.add(aclass);
                });

                classes.forEach(function(aclass){
                    snippetContainer.classList.add(aclass);
                });

                design.forEach(function(aclass){
                    snippetContainer.classList.add(aclass);
                });

                // There is a certain amount of cludgery going on here.
                // RESPONSIVE: we dont want the classes to be forced into a grid. Or have default paddings.
                // otherwise:  we dont want "card" class because that will select Bootstrap card stuff.
                // @todo This lot needs sorting out now we are always RESPONSIVE.

                let snippetsAttachmentPoint = snippetContainer;
                if (RESPONSIVE) {
                    if (snippetContainer) {

                        snippetContainer.classList.add('container-fluid', 'responsive');

                        // Replace legacy grid with Bootstrap responsive grid
                        if (snippetContainer.classList.contains('grid')) {
                            snippetsAttachmentPoint = responsiveGrid(snippetContainer, rdesign);
                        }

                        if (snippetContainer.classList.contains('fullwidth') && !snippetContainer.classList.contains('p-0')) {
                            snippetContainer.classList.add('p-0')
                        }
                        snippetContainer.classList.remove('card', 'grid-4'); // @todo 'card' may be irrelevant now. 'grid-4' may still be relevant.

                    }
                } else {
                    if (snippetContainer) {
                        snippetContainer.classList.add('unresponsive');
                        if (snippetContainer.classList.contains('card')) {
                            snippetContainer.classList.remove('card')
                            snippetContainer.classList.add('bespoke-cards')
                        }
                    }
                }

                // if required reverse the order of the snippets

                if (order === 'desc') {
                    response.reverse();
                }

                // build and append the snippets

                response.forEach(function(result, i){

                    if (i < maxItems) {

                        if (result.content.length === 0) {
                            console.log(`Error: empty snippet ${result.name}(${result.id})`);
                        } else if (result.restrictions && result.restrictions.length > 0) {
                            console.log(`Warning: snippet ${result.name}(${result.id}) restricted`);
                        } else {

                            // create temporary div to get .snippet child element
                            let snippet = document.createElement('div');
                            snippet.innerHTML = result.content;

                            let snippetError = snippet.querySelector('.snippet.error');
                            if (snippetError) {
                                console.log(`Error: snippet ${result.name}(${result.id}) ${snippetError.textContent}`);
                            } else {
                                let snippetChild = snippet.querySelector('.snippet');
                                if (snippetChild) {
                                    // append only the one .snippet element
                                    snippetChild.setAttribute('data-id', i+1);
                                    snippetsAttachmentPoint.appendChild(snippetChild);
                                } else {
                                    console.log(`Warning: appending full snippet ${result.name}(${result.id})`);
                                    snippet = createElementFromHTML(result.content);
                                    snippetContainer.appendChild(snippet);
                                }

                            }

                        }

                    }

                });

                item.appendChild(snippetContainer);

                // EPL-203: attach listener to video player to ensure views get counted

                item.querySelectorAll('.videoplayer').forEach(item => {

                    let id = item.getAttribute('data-id');
                    let iframe = item.querySelector('iframe');

                    if (typeof Vimeo == "undefined") {
                        console.log("Warning: the Vimeo object is not available");
                    } else {
                        let player = new Vimeo.Player(iframe);

                        if (typeof player == "undefined") {
                            console.log('Warning: video player undefined');
                        } else {
                            WidgetVideoViewsManager({
                                id: id,
                                player: player
                            });
                        }

                    }


                });

                // EPL-251 eval any scripts added to the DOM by the snippet

                snippetContainer.querySelectorAll('script').forEach(function(e){
                    eval(e.innerHTML);
                });

            }

        };

        let error = function(response){
            console.log('ERROR');
            console.log(response);
        };

        Ajax.getRequest(route, success, error);

    }


    return {
        getSnippetsByTags: getSnippetsByTags
    };

};

/**
 * Create element from first child e.g. a div.
 * @param html
 * @returns {ChildNode}
 */
let createElementFromHTML = function (html) {
    let div = document.createElement('div');
    div.innerHTML = html.trim();
    return div.firstChild;
}


export default WidgetSnippets;
